<template>
  <div id="cargoCard">
    <el-dialog
      :title="cargoCardFormTitle"
      width="680px"
      :visible.sync="cargoCardDialogVisible"
      :close-on-click-modal="false"
      @close="cargoCardDialogClose"
    >
      <el-form
        ref="cargoCardFormRef"
        :model="cargoCardForm"
        :rules="cargoCardFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="物料编号" prop="materialNo">
              <el-input v-model="cargoCardForm.materialNo" placeholder="请输入物料编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="名称" prop="materialName">
              <el-input v-model="cargoCardForm.materialName" placeholder="请输入名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="materialBatchNo">
              <el-input v-model="cargoCardForm.materialBatchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格/装量" prop="spec">
              <el-input v-model="cargoCardForm.spec" placeholder="请输入规格/装量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验单号" prop="inspectionOrderNo">
              <el-input v-model="cargoCardForm.inspectionOrderNo" placeholder="请输入检验单号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期" prop="validPeriod">
              <el-date-picker v-model="cargoCardForm.validPeriod" placeholder="请选择有效期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="供方" prop="supplier">
              <el-input v-model="cargoCardForm.supplier" placeholder="请输入供方" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="storageDate">
              <el-date-picker v-model="cargoCardForm.storageDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="入库数量" prop="inboundQuantity">
              <el-input v-model="cargoCardForm.inboundQuantity" placeholder="请输入入库数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发料数量" prop="issuedAmount">
              <el-input v-model="cargoCardForm.issuedAmount" placeholder="请输入发料数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结存" prop="balance">
              <el-input v-model="cargoCardForm.balance" placeholder="请输入结存" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="去向" prop="whereabouts">
              <el-input v-model="cargoCardForm.whereabouts" placeholder="请输入去向" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经手人" prop="dealer">
              <el-input v-model="cargoCardForm.dealer" placeholder="请输入经手人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="cargoCardForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cargoCardDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="cargoCardFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="名称">
        <el-input v-model="searchForm.materialName" placeholder="请输入名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="cargoCardPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="materialNo" label="物料编号" />
      <el-table-column prop="materialName" label="名称" />
      <el-table-column prop="materialBatchNo" label="批号" />
      <el-table-column prop="spec" label="规格/装量" />
      <el-table-column prop="inspectionOrderNo" label="检验单号" />
      <el-table-column label="有效期">
        <template slot-scope="scope">
          <span v-if="scope.row.validPeriod">{{ scope.row.validPeriod.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="supplier" label="供方" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.storageDate">{{ scope.row.storageDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="inboundQuantity" label="入库数量" />
      <el-table-column prop="issuedAmount" label="发料数量" />
      <el-table-column prop="balance" label="结存" />
      <el-table-column prop="whereabouts" label="去向" />
      <el-table-column prop="dealer" label="经手人" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="cargoCardPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addCargoCard, deleteCargoCard, updateCargoCard, selectCargoCardInfo, selectCargoCardList } from '@/api/storage/cargoCard'

export default {
  data () {
    return {
      cargoCardDialogVisible: false,
      cargoCardFormTitle: '',
      cargoCardForm: {
        id: '',
        materialNo: '',
        materialName: '',
        materialBatchNo: '',
        spec: '',
        inspectionOrderNo: '',
        validPeriod: '',
        supplier: '',
        storageDate: '',
        inboundQuantity: '',
        issuedAmount: '',
        balance: '',
        whereabouts: '',
        dealer: '',
        remarks: ''
      },
      cargoCardFormRules: {
        materialName: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      cargoCardPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        materialName: ''
      }
    }
  },
  created () {
    selectCargoCardList(this.searchForm).then(res => {
      this.cargoCardPage = res
    })
  },
  methods: {
    cargoCardDialogClose () {
      this.$refs.cargoCardFormRef.resetFields()
    },
    cargoCardFormSubmit () {
      if (this.cargoCardFormTitle === '货位卡详情') {
        this.cargoCardDialogVisible = false
        return
      }
      this.$refs.cargoCardFormRef.validate(async valid => {
        if (valid) {
          if (this.cargoCardFormTitle === '新增货位卡') {
            await addCargoCard(this.cargoCardForm)
          } else if (this.cargoCardFormTitle === '修改货位卡') {
            await updateCargoCard(this.cargoCardForm)
          }
          this.cargoCardPage = await selectCargoCardList(this.searchForm)
          this.cargoCardDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.cargoCardFormTitle = '新增货位卡'
      this.cargoCardDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteCargoCard(row.id)
        if (this.cargoCardPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.cargoCardPage = await selectCargoCardList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.cargoCardFormTitle = '修改货位卡'
      this.cargoCardDialogVisible = true
      this.selectCargoCardInfoById(row.id)
    },
    handleInfo (index, row) {
      this.cargoCardFormTitle = '货位卡详情'
      this.cargoCardDialogVisible = true
      this.selectCargoCardInfoById(row.id)
    },
    selectCargoCardInfoById (id) {
      selectCargoCardInfo(id).then(res => {
        this.cargoCardForm.id = res.id
        this.cargoCardForm.materialNo = res.materialNo
        this.cargoCardForm.materialName = res.materialName
        this.cargoCardForm.materialBatchNo = res.materialBatchNo
        this.cargoCardForm.spec = res.spec
        this.cargoCardForm.inspectionOrderNo = res.inspectionOrderNo
        this.cargoCardForm.validPeriod = res.validPeriod
        this.cargoCardForm.supplier = res.supplier
        this.cargoCardForm.storageDate = res.storageDate
        this.cargoCardForm.inboundQuantity = res.inboundQuantity
        this.cargoCardForm.issuedAmount = res.issuedAmount
        this.cargoCardForm.balance = res.balance
        this.cargoCardForm.whereabouts = res.whereabouts
        this.cargoCardForm.dealer = res.dealer
        this.cargoCardForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCargoCardList(this.searchForm).then(res => {
        this.cargoCardPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCargoCardList(this.searchForm).then(res => {
        this.cargoCardPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCargoCardList(this.searchForm).then(res => {
        this.cargoCardPage = res
      })
    }
  }
}
</script>

<style>
</style>
