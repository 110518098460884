import axios from '@/common/axios'
import qs from 'qs'

export function addCargoCard (data) {
  return axios({
    method: 'post',
    url: '/storage/card/add',
    data: qs.stringify(data)
  })
}

export function deleteCargoCard (id) {
  return axios({
    method: 'delete',
    url: '/storage/card/delete/' + id
  })
}

export function updateCargoCard (data) {
  return axios({
    method: 'put',
    url: '/storage/card/update',
    data: qs.stringify(data)
  })
}

export function selectCargoCardInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/card/info/' + id
  })
}

export function selectCargoCardList (query) {
  return axios({
    method: 'get',
    url: '/storage/card/list',
    params: query
  })
}
